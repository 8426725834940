import { useState, useRef, useEffect } from 'react';
import debounce from 'debounce';
import classNames from 'classnames';

import Image from '../Image/Image';
import styles from './LinkListLarge.module.scss';

const LinkListLarge = ({ title = '', items = [] }) => {
    if (!items.length) {
        return;
    }
    const ref = useRef(null);

    const [mounted, setMounted] = useState(false);
    const [isActiveIndex, setActiveIndex] = useState(null);
    const [clientRect, setClientRect] = useState({ top: null, left: null });
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        const isTouchDevice =
            'ontouchstart' in window || navigator.msMaxTouchPoints;

        setIsTouchDevice(isTouchDevice);
    });

    const handleOnMouseMovement = (index) => {
        if (isTouchDevice) {
            return;
        }

        setActiveIndex(index);
        setClientRect({
            top: ref.current.getBoundingClientRect().top,
            left: ref.current.getBoundingClientRect().left,
        });
    };

    return (
        mounted && (
            <div ref={ref} className={styles['LinkListLarge']}>
                <h2 className={styles['LinkListLarge__Title']}>{title}</h2>
                <ul className={styles['LinkListLarge__List']}>
                    {items.map((item, index) => (
                        <ListItem
                            key={index}
                            index={index}
                            {...item}
                            {...{
                                isActiveIndex,
                                isTouchDevice,
                                clientRect,
                                handleOnMouseMovement,
                            }}
                        />
                    ))}
                </ul>
            </div>
        )
    );
};

const ListItem = ({
    clientRect = {
        top: null,
        left: null,
    },
    isTouchDevice = false,
    isActiveIndex = null,
    handleOnMouseMovement = () => {},
    index = null,
    link = {},
    image = {},
}) => {
    const imageRef = useRef(null);
    const [imageWidth, setImageWidth] = useState(0);

    const [mousePosition, setMousePosition] = useState({
        x: null,
        y: null,
    });

    const handleResize = debounce(() => {
        if (isTouchDevice) {
            return;
        }
        setImageWidth(imageRef.current.offsetWidth);
    }, 600);

    useEffect(() => {
        if (isTouchDevice) {
            return;
        }
        setImageWidth(imageRef.current.offsetWidth);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    const classes = classNames(styles['LinkListLarge__Link'], {
        [styles['LinkListLarge__Link--InActive']]:
            isActiveIndex !== index && isActiveIndex !== null,
    });

    const imageClasses = classNames(styles['LinkListLarge__Image'], {
        [styles['LinkListLarge__Image--Visible']]:
            isActiveIndex === index &&
            mousePosition.y > 0 &&
            mousePosition.x > 0,
    });

    const handleMousePosition = (e) => {
        if (isTouchDevice) {
            return;
        }

        setMousePosition({
            x: e.nativeEvent.clientX,
            y: e.nativeEvent.clientY,
        });
    };

    const positionTop = mousePosition.y - clientRect.top - imageWidth / 2;
    const positionLeft = mousePosition.x - clientRect.left - imageWidth / 2;

    const style = {
        top: `${positionTop}px`,
        left: `${positionLeft}px`,
    };

    return (
        <li
            className={styles['LinkListLarge__Item']}
            onMouseEnter={() => handleOnMouseMovement(index)}
            onMouseLeave={() => handleOnMouseMovement(null)}
            onMouseMove={(e) => handleMousePosition(e)}>
            <a className={classes} href={link.href} target={link.target}>
                {link.title}
            </a>
            <figure ref={imageRef} className={imageClasses} style={style}>
                <Image {...{ image }} sizes={'(min-width: 1800px) 1080px'} />
            </figure>
        </li>
    );
};

LinkListLarge.propTypes = {};

export default LinkListLarge;
